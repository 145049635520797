import React from 'react';
import Layout from '../components/layout';
import Map from '../components/map';
import { graphql, StaticQuery } from 'gatsby';

import '../styles/main.scss';

class Location extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query locationQuery {
            map: file(relativePath: { eq: "map.png" }) {
              childImageSharp {
                fixed(width: 768) {
                  src
                }
              }
            }
            globus: file(relativePath: { eq: "icons/globus.png" }) {
              childImageSharp {
                fixed(width: 64) {
                  src
                }
              }
            }
            zlobek: file(relativePath: { eq: "icons/zlobek.png" }) {
              childImageSharp {
                fixed(width: 64) {
                  src
                }
              }
            }

            basen: file(relativePath: { eq: "icons/basen.png" }) {
              childImageSharp {
                fixed(width: 64) {
                  src
                }
              }
            }
            stacja: file(relativePath: { eq: "icons/stacja_kolejowa.png" }) {
              childImageSharp {
                fixed(width: 64) {
                  src
                }
              }
            }
            autobus: file(relativePath: { eq: "icons/autobus.png" }) {
              childImageSharp {
                fixed(width: 64) {
                  src
                }
              }
            }
            las: file(relativePath: { eq: "icons/rz_l.png" }) {
              childImageSharp {
                fixed(width: 64) {
                  src
                }
              }
            }
            przedszkole: file(relativePath: { eq: "icons/przedszkole.png" }) {
              childImageSharp {
                fixed(width: 64) {
                  src
                }
              }
            }
            plac: file(relativePath: { eq: "icons/plac_zabaw.png" }) {
              childImageSharp {
                fixed(width: 64) {
                  src
                }
              }
            }
            obwodnica: file(relativePath: { eq: "icons/obwodnica.png" }) {
              childImageSharp {
                fixed(width: 64) {
                  src
                }
              }
            }
            stadion: file(relativePath: { eq: "icons/stadion.png" }) {
              childImageSharp {
                fixed(width: 64) {
                  src
                }
              }
            }
            zakupy: file(relativePath: { eq: "icons/zakupy.png" }) {
              childImageSharp {
                fixed(width: 64) {
                  src
                }
              }
            }
            fashion: file(relativePath: { eq: "icons/fashion.png" }) {
              childImageSharp {
                fixed(width: 64) {
                  src
                }
              }
            }
            rynek: file(relativePath: { eq: "icons/rynek.png" }) {
              childImageSharp {
                fixed(width: 64) {
                  src
                }
              }
            }
            zp: file(relativePath: { eq: "icons/zp.png" }) {
              childImageSharp {
                fixed(width: 64) {
                  src
                }
              }
            }
          }
        `}
        render={data => (
          <Layout>
            <div
              className="container mt-5"
              style={{
                marginBottom: ' 50px',
              }}
            >
              <h2 className="head-underlined">Dlaczego zatoka pucka ?</h2>

              <p className="mt-3 text-justify">
                Każdy człowiek potrzebuje odnaleźć swoje własne miejsce do
                życia. Wracając z dalekiej podróży, z pracy czy ze szkoły chcemy
                czuć spokój, mieć swój kąt, w którym czujemy się bezpiecznie.
                Zatoka Pucka ma być właśnie takim miejscem we Wrocławiu.
                Wyjątkowym, cieszącym oko, ale i duszę. Nowoczesnym zakątkiem
                położonym blisko centrum miasta,&nbsp;a&nbsp;jednocześnie dobrym
                miejscem z przestrzenią do życia i do wypoczynku.
              </p>

              <p className="mt-3 text-justify">
                Dogodną komunikację gwarantuje także bezpośrednie sąsiedztwo z
                głównymi arteriami miasta oraz bezproblemowy dostęp do
                komunikacji, do AOW, stadionu miejskiego, centrów handlowych
                oraz dojazd do wrocławskiego Rynku. Bezpośrednie otoczenie
                inwestycji stanowi zabudowa szeregowa oraz jednorodzinna. Tuż
                obok znajduje się przedszkole, szkoła podstawowa oraz liceum.
                Okolica obfituje w liczne punkty usługowe i handlowe, zielone
                skwerki, plac zabaw i siłownię zewnętrzną.
              </p>
            </div>
            <div
              className="container mt-5 location-wrapper"
              style={{
                marginBottom: ' 50px',
              }}
            >
              <h2 className="head-underlined">Komunikacja i lokalizacja</h2>
              <div className="map-img-wrapper d-flex flex-column">
                <div className="mt-5 d-flex">
                  <img
                    src={data.obwodnica.childImageSharp.fixed.src}
                    className="img-fluid icon"
                  />
                  <div className="ml-5 d-flex flex-column justify-content-center">
                    <h3>
                      <b className="text-uppercase">AOW</b> - 7 minut - 3,4 km
                    </h3>
                  </div>
                </div>
                <div className="mt-5 mb-3 d-flex">
                  <img
                    src={data.zakupy.childImageSharp.fixed.src}
                    className="img-fluid icon"
                  />
                  <div className="ml-5 d-flex flex-column justify-content-center">
                    <h3>
                      <b className="text-uppercase">Mangolia park</b> - 6 minut
                      - 3,3 km
                    </h3>
                  </div>
                </div>
                <div className="mt-5 d-flex">
                  <img
                    src={data.stadion.childImageSharp.fixed.src}
                    className="img-fluid icon"
                  />
                  <div className="ml-5 d-flex flex-column justify-content-center">
                    <h3>
                      <b className="text-uppercase">Stadion Miejski</b> - 7
                      minut - 3,4 km
                    </h3>
                  </div>
                </div>
                <div className="mt-5 d-flex">
                  <img
                    src={data.fashion.childImageSharp.fixed.src}
                    className="img-fluid icon"
                  />
                  <div className="ml-5 d-flex flex-column justify-content-center">
                    <h3>
                      <b className="text-uppercase">Wrocław Fashion Outlet</b> -
                      9 minut - 3,6 km
                    </h3>
                  </div>
                </div>
                <div className="mt-3 d-flex">
                  <img
                    src={data.rynek.childImageSharp.fixed.src}
                    className="img-fluid icon"
                  />
                  <div className="ml-5 d-flex flex-column justify-content-center">
                    <h3>
                      <b className="text-uppercase">Rynek</b> - 16 minut - 7,3
                      km
                    </h3>
                  </div>
                </div>
                <div className="mt-3 d-flex">
                  <img
                    src={data.zp.childImageSharp.fixed.src}
                    className="img-fluid icon"
                  />
                  <div className="ml-5 d-flex flex-column justify-content-center">
                    <h3>
                      <b className="text-uppercase">Zatoka Pucka</b> - 6 h - 495
                      km
                    </h3>
                  </div>
                </div>
                <h2 className="head-underlined special-head">Mapa Wrocławia</h2>
                <img
                  className="img-fluid map mt-5"
                  src={data.map.childImageSharp.fixed.src}
                />
              </div>
              <div />
            </div>
            <div
              className="container mt-5"
              style={{
                marginBottom: ' 50px',
              }}
            >
              <Map />
            </div>
            <div
              className="container mt-5"
              style={{
                marginBottom: ' 50px',
              }}
            >
              <h2 className="head-underlined">Najbliższa okolica</h2>
              <div className="mt-5 d-flex">
                <img
                  src={data.plac.childImageSharp.fixed.src}
                  className="img-fluid icon"
                />
                <div className="ml-5 d-flex flex-column justify-content-center">
                  <h3>
                    <b className="text-uppercase">
                      DUŻY I DOBRZE WYPOSAŻONY OGÓLNODOSTĘPNY PLAC ZABAW
                    </b>{' '}
                    - 150 m
                  </h3>
                </div>
              </div>
              <div className="mt-5 d-flex">
                <img
                  src={data.przedszkole.childImageSharp.fixed.src}
                  className="img-fluid icon"
                />
                <div className="ml-5 d-flex flex-column justify-content-center">
                  <h3>
                    <b className="text-uppercase">PRZEDSZKOLE</b> - 200 m
                  </h3>
                </div>
              </div>
              <div className="mt-5 d-flex">
                <img
                  src={data.las.childImageSharp.fixed.src}
                  className="img-fluid icon"
                />
                <div className="ml-5 d-flex flex-column justify-content-center">
                  <h3>
                    <b className="text-uppercase">
                      LAS KUŹNICKI WRAZ Z RZEKĄ ŚLĘŻĄ
                    </b>{' '}
                    - 300 m
                  </h3>
                </div>
              </div>
              <div className="mt-5 d-flex">
                <img
                  src={data.autobus.childImageSharp.fixed.src}
                  className="img-fluid icon"
                />
                <div className="ml-5 d-flex flex-column justify-content-center">
                  <h3>
                    <b className="text-uppercase">
                      DLA NIEZMOTORYZOWANYCH AUTOBUSY LINII 122, 126 ORAZ 129,
                      149
                    </b>{' '}
                    - 500 m
                  </h3>
                </div>
              </div>
              <div className="mt-5 d-flex">
                <img
                  src={data.stacja.childImageSharp.fixed.src}
                  className="img-fluid icon"
                />
                <div className="ml-5 d-flex flex-column justify-content-center">
                  <h3>
                    <b className="text-uppercase">STACJA KOLEJOWA KUŹNIKI</b> -
                    700 m
                  </h3>
                </div>
              </div>
              <div className="mt-5 d-flex">
                <img
                  src={data.basen.childImageSharp.fixed.src}
                  className="img-fluid icon"
                />
                <div className="ml-5 d-flex flex-column justify-content-center">
                  <h3>
                    <b className="text-uppercase">
                      CENTRUM SPORTOWO-REKREACYJNE Z BASENEM
                    </b>{' '}
                    - 1000 m
                  </h3>
                </div>
              </div>
              <div className="mt-5 d-flex">
                <img
                  src={data.zlobek.childImageSharp.fixed.src}
                  className="img-fluid icon"
                />
                <div className="ml-5 d-flex flex-column justify-content-center">
                  <h3>
                    <b className="text-uppercase">ŻŁOBEK</b> - 1000 m
                  </h3>
                </div>
              </div>
              <div className="mt-5 d-flex">
                <img
                  src={data.globus.childImageSharp.fixed.src}
                  className="img-fluid icon"
                />
                <div className="ml-5 d-flex flex-column justify-content-center">
                  <h3>
                    <b className="text-uppercase">
                      SZKOŁA PODSTAWOWA ORAZ LICEUM
                    </b>{' '}
                    - 1100 m
                  </h3>
                </div>
              </div>
            </div>
            <div
              className="container mt-5"
              style={{
                marginBottom: ' 50px',
              }}
            >
              <h2 className="head-underlined">
                Tereny rekreacyjne i spacerowe
              </h2>

              <p className="mt-3 text-justify">
                Spokój i harmonia nie są możliwe bez zachowania odpowiedniego
                poziomu aktywności. Ruch na świeżym powietrzu jest wpisany
                zresztą w ludzką naturę. Zatoka Pucka umożliwia łatwy dostęp do
                terenów rekreacyjnych - dzięki temu można czerpać radość z
                przejażdżki rowerem, uprawiać poranny jogging czy nordic walking
                po Lasku Kuźnickim.
              </p>
            </div>
          </Layout>
        )}
      />
    );
  }
}

export default Location;
