import React from 'react';
import { compose, withProps } from 'recompose';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps';
import zatokaPuckaImg from '../images/zatoka-pucka-logo.png';
import przedszkoleImg from '../images/icons/map/kindergarden.png';
import zlobekImg from '../images/icons/map/nursery.png';
import stacjaImg from '../images/icons/map/railwaystation.png';
import autobusImg from '../images/icons/map/busstop.png';
import szkolaImg from '../images/icons/map/school.png';

const Map = compose(
  withProps({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyDEZlPogk_PR2bqa72mIrY7fmXgSLap7z4&v=3.exp&libraries=geometry,drawing,places',
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `600px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap
    defaultZoom={16}
    defaultCenter={{ lat: 51.125013, lng: 16.942852 }}
    options={{
      styles: [
        {
          featureType: 'all',
          elementType: 'labels.text.fill',
          stylers: [
            {
              saturation: '0',
            },
            {
              color: '#404363',
            },
            {
              lightness: '0',
            },
          ],
        },
        {
          featureType: 'all',
          elementType: 'labels.text.stroke',
          stylers: [
            {
              visibility: 'on',
            },
            {
              color: '#ffffff',
            },
            {
              lightness: 16,
            },
          ],
        },
        {
          featureType: 'all',
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'administrative',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#fefefe',
            },
            {
              lightness: 20,
            },
          ],
        },
        {
          featureType: 'administrative',
          elementType: 'geometry.stroke',
          stylers: [
            {
              color: '#fefefe',
            },
            {
              lightness: 17,
            },
            {
              weight: 1.2,
            },
          ],
        },
        {
          featureType: 'administrative',
          elementType: 'labels.text.fill',
          stylers: [
            {
              lightness: '-36',
            },
          ],
        },
        {
          featureType: 'landscape',
          elementType: 'geometry',
          stylers: [
            {
              color: '#f1f1f1',
            },
            {
              lightness: '0',
            },
          ],
        },
        {
          featureType: 'poi',
          elementType: 'geometry',
          stylers: [
            {
              color: '#f1f1f1',
            },
            {
              lightness: '-5',
            },
            {
              saturation: '0',
            },
          ],
        },
        {
          featureType: 'poi.park',
          elementType: 'geometry',
          stylers: [
            {
              color: '#59bf97',
            },
            {
              lightness: '60',
            },
            {
              saturation: '-10',
            },
          ],
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.fill',
          stylers: [
            {
              color: '#ffffff',
            },
            {
              lightness: 17,
            },
          ],
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.stroke',
          stylers: [
            {
              color: '#ffffff',
            },
            {
              lightness: 29,
            },
            {
              weight: 0.2,
            },
          ],
        },
        {
          featureType: 'road.arterial',
          elementType: 'geometry',
          stylers: [
            {
              color: '#ffffff',
            },
            {
              lightness: 18,
            },
          ],
        },
        {
          featureType: 'road.local',
          elementType: 'geometry',
          stylers: [
            {
              color: '#ffffff',
            },
            {
              lightness: 16,
            },
          ],
        },
        {
          featureType: 'transit',
          elementType: 'geometry',
          stylers: [
            {
              color: '#606383',
            },
            {
              lightness: 19,
            },
          ],
        },
        {
          featureType: 'water',
          elementType: 'geometry',
          stylers: [
            {
              color: '#20223e',
            },
            {
              lightness: 17,
            },
          ],
        },
        {
          featureType: 'water',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#ffffff',
            },
          ],
        },
      ],
    }}
  >
    <Marker
      icon={{
        url: zatokaPuckaImg,
        scaledSize: new window.google.maps.Size(48, 48),
      }}
      position={{ lat: 51.125013, lng: 16.942852 }}
    />
    <Marker
      icon={{
        url: przedszkoleImg,
        scaledSize: new window.google.maps.Size(48, 48),
      }}
      position={{ lat: 51.1253558, lng: 16.94502 }}
    />
    <Marker
      icon={{
        url: zlobekImg,
        scaledSize: new window.google.maps.Size(48, 48),
      }}
      position={{ lat: 51.1155905, lng: 16.9470086 }}
    />
    <Marker
      icon={{
        url: szkolaImg,
        scaledSize: new window.google.maps.Size(48, 48),
      }}
      position={{ lat: 51.115592, lng: 16.949197 }}
    />
    <Marker
      icon={{
        url: autobusImg,
        scaledSize: new window.google.maps.Size(48, 48),
      }}
      position={{ lat: 51.1239635, lng: 16.9486804 }}
    />
    <Marker
      icon={{
        url: stacjaImg,
        scaledSize: new window.google.maps.Size(48, 48),
      }}
      position={{ lat: 51.1260571, lng: 16.9498605 }}
    />
  </GoogleMap>
));

export default Map;
